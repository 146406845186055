<template>
  <div class="wap-recharge" :class="pageType === 'user' ? 'user' : ''">
    <headerBar
      :title="$t('chong-zhi-1')"
      @back="back"
      :right-text="$t('chong-zhi-ji-lu')"
      @detail="toDetail"
    ></headerBar>
    <div class="recharge-form">
      <div class="qrcode-box flex-column-center">
        <div class="qrcode" ref="qrcode" id="qrcode"></div>
        <Button>{{ $t('bao-cun-er-wei-ma') }}</Button>
      </div>

      <div class="form-item">
        <div class="label">{{ $t('chong-zhi-di-zhi') }}</div>
        <div class="input">
          <Field v-model="address" readonly>
            <template #button>
              <span class="link copy-icon" @click="copyData">{{
                $t('fu-zhi')
              }}</span>
            </template>
          </Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{ $t('chong-zhi-shu-liang') }}</div>
        <div class="input">
          <Field
            v-model="form.number"
            :placeholder="$t('qing-shu-ru-chong-zhi-shu-liang-1')"
          ></Field>
        </div>
      </div>
      <div class="form-item">
        <div class="label">
          {{ $t('shang-chuan-tu-pian-shang-chuan-zhi-fu-xiang-qing-jie-tu-0') }}
        </div>
        <div class="upload">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :action="uploadUrl"
            name="file"
            :headers="headers"
            :on-success="uploadSuccess"
            :before-upload="beforeUpload"
          >
            <img v-if="form.logo" :src="getBaseUrl(form.logo)" class="avatar" />
            <i
              v-else
              class="el-icon-camera-solid"
              style="font-size: 30px; color: #aaa"
            ></i>
          </el-upload>
        </div>
      </div>

      <div class="form-item">
        <Button class="submit-btn" @click="submit">{{ $t('ti-jiao') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import Clipboard from 'clipboard'
import headerBar from '@/components/header'
import { Field, Button } from 'vant'
import { getToken } from '@/utils/auth'
import { getRechargeInfo, addRecharge } from '@/api/user'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    headerBar,
    Field,
    Button
  },
  data() {
    return {
      form: {
        link: '',
        number: ''
      },
      headers: {},
      address: '',
      pageType: '',
      bannerUrl: require('@/assets/imgs/recharge-bg.png'),
      bitList: [
        {
          icon: require('@/assets/imgs/icon-bit1.png'),
          name: 'BTC',
          key: 'btc',
          linkList: [
            {
              key: 'OMNI',
              name: 'OMNI'
            }
          ]
        },
        {
          icon: require('@/assets/imgs/icon-bit2.png'),
          name: 'ETH',
          key: 'eth',
          linkList: [
            {
              key: 'erc20',
              name: 'ERC20'
            }
          ]
        },
        {
          icon: require('@/assets/imgs/icon-bit3.png'),
          name: 'USDT',
          key: 'usdt',
          linkList: [
            {
              key: 'USDT-TRC20',
              name: 'TRC20'
            },
            {
              key: 'USDT-ERC20',
              name: 'ERC20'
            }
          ]
        }
      ],
      linkList: []
    }
  },
  computed: {
    imgType() {
      return this.$store.state.imgType
    },

    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.init()
    this.headers = {
      Token: getToken()
    }

    let name = this.$route.name
    this.pageType = name == 'wapUserRechargeInfo' ? 'user' : ''
  },
  methods: {
    getBaseUrl,
    init() {
      let key = this.$route.query.type
      getRechargeInfo().then((res) => {
        this.addressList = res.data.Items

        this.address = this.addressList.find(
          (v) => v.RechargeNetwork == key
        ).RechargeAddress
        let qrcode = new QRCode(this.$refs.qrcode, {
          text: this.address,
          width: 120,
          height: 120,
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    back() {
      this.$router.go(-1)
    },
    copyData() {
      let data = this.address
      const clipboard = new Clipboard('.copy-icon', { text: () => data })
      this.$toast(this.$t('fu-zhi-cheng-gong'))
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    uploadSuccess(file) {
      this.form.logo = file.data.FileName
      this.$forceUpdate()
    },
    changeLink(data) {
      this.form.link = data.key
      this.address = this.addressList.find(
        (v) => v.RechargeNetwork == this.form.link
      ).RechargeAddress
      document.getElementById('qrcode').innerHTML = ''
      let qrcode = new QRCode(this.$refs.qrcode, {
        text: this.address,
        width: 120,
        height: 120,
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    submit() {
      addRecharge({
        Amount: parseFloat(this.form.number),
        PaymentVoucher: this.form.logo
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('ti-jiao-cheng-gong'))
          this.form.logo = ''
          this.form.number = ''
        } else {
          this.$toast(res.msg)
        }
      })
    },
    toDetail() {
      this.$router.push({
        name: 'wapRechargeLog'
      })
    }
  }
}
</script>